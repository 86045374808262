<template>
  <div w="full" min-h="full" relative bg="white">
    <div class="h-58 w-full flex flex-col" :style="{ backgroundColor: 'var(--tc-color-bg-index)' }">
      <BusinessBUserHeader />
      <div
        flex="~ 1 col items-center justify-center" text="center white xl"
        class="-pre-wrap px-6 pb-10 font-600 leading-tight"
        :style="{
          background: 'center center no-repeat',
          backgroundSize: 'cover',
          backgroundImage: `url(${bgImg})`,
        }"
      >
        <!-- <div class="flex-1 whitespace-pre-wrap text-xl font-600 leading-tight"> -->
        <div class="whitespace-pre-wrap" :style="{ color: 'var(--tc-color-title-index)' }">
          {{ pageTitle }}
        </div>
        <div v-if="subTitle" class="mt-3 whitespace-pre-wrap text-sm" :style="{ color: 'var(--tc-color-subtitle-index)' }">
          {{ subTitle }}
        </div>
        <!-- </div> -->
      </div>
    </div>

    <div class="position-relative px-3.5 pt-4.5 -mt-12">
      <div class="rounded-xl bg-white px-4 py-1 shadow-[0_2px_8px_0px_rgba(122,122,122,0.25)]">
        <!-- 选择地址 -->
        <MobileSearchDestination class="my-4" page-type="home" />
        <!-- 选择日期 -->
        <MobileSelectDate page-type="home">
          <template #default="{ formatDate }">
            <div flex="~ items-center" p="x-3.5" h="13" b="1 solid #B3B3B3" rounded="2.5">
              <img class="w-7" src="~assets/icons/icon-calendar.png" />
              <div v-if="formatDate" class="text-base text-[#1B1B1B] font-500" m="l-3.5">
                {{ formatDate }}
              </div>
              <div v-else text="base #B1B1B1" m="l-3.5">
                Select your dates
              </div>
            </div>
          </template>
        </MobileSelectDate>
        <div class="van-hairline--top bg-[#B3B3B3]"></div>
        <!-- 选择门票类型 -->
        <!-- <BusinessBSelectTicket page-type="home">
          <template #default="{ checkedLabel }">
            <div class="flex justify-between p-3">
              <div class="flex flex-1">
                <img w="7" h="7" src="~assets/icons/icon-ticket.png" class="flex-shrink-0" />
                <div class="van-hairline--right mx-4 bg-[#B3B3B3]"></div>
                <div class="text-lg text-[#1B1B1B] font-500">
                  {{ checkedLabel }}
                </div>
                <img class="ml-3 flex-shrink-0 self-center" w="4" h="4" src="~assets/icons/icon-person.png" />
              </div>
              <img class="float-right self-center" w="4" h="4" src="~assets/icons/icon-arrow-down.png" />
            </div>
          </template>
        </BusinessBSelectTicket> -->
        <div class="van-hairline--top bg-[#B3B3B3]"></div>
        <div m="y-3">
          <VanButton :block="true" size="large" color="#000000" style="--van-button-radius: 10px;" @click="handleSearch">
            <div text="center lg white" font="800">
              {{ t('key.home.searchTicket') }}
            </div>
          </VanButton>
        </div>
      </div>
    </div>

    <!-- 合作品牌 -->
    <BusinessBPartnerBrands />
    <div class="van-hairline--top mt-8 bg-[#B3B3B3]"></div>
    <!-- 如何赚取 -->
    <BusinessBHowToEarn />
    <!-- 底部介绍 -->
    <MobileFooter />
  </div>
</template>

<script lang="ts" setup>
import { ENUM_EventType, ENUM_PageName } from '~/enums'
import type { SearchClickEvent } from '~/types/event-tracking'

const { t } = useI18n()

useSeoMeta({
  title: t('key.attractions.index.title'),
  description: t('key.attractions.index.descrition'),
})

const gtm = useGtm()
const filterConditionsStore = useFilterConditionsStore()
const dayjs = useDayjs()
const { query } = useRoute()
const { locale } = useLocale()

const { pageTitle, subTitle, bgImg } = usePartnerConfig()
const { handleHomeSearch } = useUseHomePopup()

function handleSearch() {
  handleHomeSearch()
  const eventParams: SearchClickEvent = {
    event: ENUM_EventType.Search,
    page_category: ENUM_PageName.Home,
    city: filterConditionsStore.filterConditions.selectedCity?.destinationName,
    city_id: filterConditionsStore.filterConditions.selectedCity?.destinationId,
    country_code: filterConditionsStore.filterConditions.selectedCity?.countryCode,
    search_date: dayjs(filterConditionsStore.filterConditions.date).format('YYYY-MM-DD'),
    guest_number: generateGuestNumber(filterConditionsStore.filterConditions.listTagList),
    locale: locale.value,
    campaign_id: query.campaign_id as string ?? '',
    campaign_name: query.campaign_name as string ?? '',
  }

  gtm?.trackEvent(eventParams)
}
</script>
